<template>
    <div>
        <div v-if="authUserPermission['mail-configuration-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('settings') }}</h3>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="danger" @click="update" :disabled="btnSave.onLoading">
                                    <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                    <span v-else>{{ tt('update') }}</span>
                                </base-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" style="height:600px">
                        <div v-if="!onLoading">
                            <base-input :name="maxFile.description" :label="maxFile.description" v-model="maxFile.value" :placeholder="tt('value')"  rules="required"></base-input>
                        </div>
                        <skeleton-loading v-else/>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import generalSetting from '@/services/setting/generalSettings.service';
    import SkeletonLoading from '../../components/SkeletonLoading.vue';

    export default {
  components: { SkeletonLoading },        
        data() {
            return {                
                settings: {},
                maxFile:{
                    value:null,
                    description:null,
                },
                btnSave: {
                    onLoading: false
                },
                onLoading:false,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;    
                this.onLoading = true;          
                Api(context, generalSetting.getMaximumFile()).onSuccess(function(response) { 
                    context.maxFile.value = (JSON.parse(response.data.data.value)).max_file 
                    context.maxFile.description = response.data.data.description   
                }).onFinish(() =>{
                    context.onLoading = false;
                }).call()
            },
            update() {
                let context = this;
                context.confirmDialog(this.tt('confirm_update')).then((result) => {
                    if (result.value) {
                        this.btnSave.onLoading = true; 
                        Api(context, generalSetting.updateMaximumFile({ value: context.maxFile.value})).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).onFinish(() =>{
                            context.btnSave.onLoading = false;
                        }).call();
                        context.alertDialog('success', this.tt(success_update));
                    }
                })
            },
        }   
    };
    
</script>
<style></style>
